import { render, staticRenderFns } from "./CompetencyMatrix.vue?vue&type=template&id=755a8676&scoped=true"
import script from "./CompetencyMatrix.vue?vue&type=script&lang=ts"
export * from "./CompetencyMatrix.vue?vue&type=script&lang=ts"
import style0 from "./CompetencyMatrix.vue?vue&type=style&index=0&id=755a8676&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "755a8676",
  null
  
)

export default component.exports