
import "@kangc/v-md-editor/lib/style/base-editor.css";
import "@kangc/v-md-editor/lib/theme/style/vuepress.css";

import { Component, Prop, Emit, Ref } from "vue-property-decorator";
import { CanadidateReportInfo, TemplateSchema, TypedVue } from "@/store/types";

import Editor from "@tinymce/tinymce-vue";
import VueMarkdownEditor from "@kangc/v-md-editor";
import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
import enUS from "@kangc/v-md-editor/lib/lang/en-US";

import EditReportCompetencyComponent from "@/components/admin/EditReportCompetencyComponent.vue";
import ReportRenderer from "@/components/reports/ReportRenderer.vue";
import { Action } from "vuex-class";
import { VuexModules } from "@/store";
import { Actions } from "@/store/admin/adminActions";
import { Resource } from "@/store/admin/adminTypes";
import { RenderHBTemplate } from "@/lib/templates/TemplateGenerator";

VueMarkdownEditor.use(vuepressTheme);
VueMarkdownEditor.lang.use("en-US", enUS);

const namespace = VuexModules.ADMIN;

@Component({
  components: {
    EditReportCompetencyComponent,
    ReportRenderer,
    VueMarkdownEditor,
    Editor,
  },
})
export default class EditReportComponent extends TypedVue {
  @Prop({ required: true })
  resource!: Resource;
  @Prop({ required: true })
  templateHTML!: string;
  @Prop({ required: true })
  reportData!: CanadidateReportInfo;
  @Prop({ default: false })
  publishing!: boolean;
  @Ref("editorRenderedTemplate")
  editorRenderedTemplate!: HTMLElement;

  tinyMCEConfig = {
    height: 500,
    menubar: true,
    inline: true,
    plugins: ["advlist autolink lists link image charmap print preview", "searchreplace visualblocks code fullscreen", "insertdatetime media table paste code help wordcount"],
    extended_valid_elements: "div[*],b,a[*],i[*]",
    relative_urls: false,
    remove_script_host: false,
    toolbar:
      "undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help",
  };

  template = "";
  editedTemplate = "";
  sendEmail = false;
  useEditorHelpers = true;
  showEditor = true;

  get templateEditorRender() {
    const templateData = {
      ...this.reportData.data,
      readiness: this.reportData.readiness,
      showPII: true,
      rs: this.resource.dictionary,
      project_theme: "default",
      version: this.reportData.version,
    };

    return RenderHBTemplate(this.templateHTML, templateData, this.resource.dictionary, this.useEditorHelpers);
  }

  @Emit("onPublish")
  onPublish(templateHTML: string, sendEmail: boolean) {
    return { templateHTML, sendEmail };
  }

  @Action(Actions.GET_LITERALS, { namespace })
  getLiterals!: (module: string, verison: string) => Promise<Resource>;

  @Action(Actions.GET_TEMPLATE, { namespace })
  getTemplate!: (templateName: string) => Promise<TemplateSchema | null>;

  get reportDataString(): string {
    return JSON.stringify(this.reportData?.data, undefined, 4);
  }

  get reportTemplateContent(): string {
    return this.editedTemplate === "" ? this.template : this.editedTemplate;
  }

  removeEditorHelpers() {
    this.useEditorHelpers = false;

    // This will trigger tinymce to reload
    this.showEditor = false;
    this.$nextTick(() => {
      this.showEditor = true;
    });
  }

  mounted() {
    console.log(this.reportData);
    this.template = this.templateHTML;
  }

  handlePublish(): void {
    this.onPublish(this.reportTemplateContent, this.sendEmail);
  }

  addComment(): void {
    if (this.reportData) {
      this.reportData.data.comment = {
        title: "",
        body: "",
      };
    }
  }

  resetTemplate() {
    this.template = this.templateHTML;
  }
}
