import { Resource } from "@/store/admin/adminTypes";
import adminService from "@/services/adminService";

const resourceCache: Record<string, Record<string, Resource>> = {};
let latestVersion = "";

export async function getResource(module = "admin", lang = "en"): Promise<Resource> {
  if (latestVersion && resourceCache[latestVersion] && resourceCache[latestVersion][lang]) {
    return resourceCache[latestVersion][lang];
  }

  const res = await adminService.getModuleResource(module, lang);
  latestVersion = res.version;
  saveResource(lang, res.version, res);

  return res;
}

export async function getResourceByVersion(module = "admin", lang: string, version: string): Promise<Resource> {
  if (resourceCache[version] && resourceCache[version][lang]) {
    return resourceCache[version][lang];
  }

  const res = await adminService.getModuleResource(module, lang, version);
  saveResource(lang, version, res);

  return res;
}

function saveResource(lang: string, version: string, res: Resource) {
  if (!resourceCache[version]) {
    resourceCache[version] = {};
  }

  resourceCache[version][lang] = res;
}
