import { GetterTree } from "vuex";
import { ProjectTheme, StoreState, V2Project } from "./adminTypes";
import { RootState } from "../types";
import { emptyResource } from "../emptyModels";
export enum Getters {
  GET_CURRENT_PROJECT_THEME = "GET_CURRENT_THEME",
  GET_LITERALS = "GET_LITERALS",
  GET_CURRENT_PROJECT = "GET_CURRENT_PROJECT",
  GET_PROJECTS = "GET_PROJECTS",
  GET_CURRENT_PROJECT_BENCHMARKS = "GET_CURRENT_PROJECT_BENCHMARKS",
  GET_CURRENT_PROJECT_AGGREGATES = "GET_CURRENT_PROJECT_AGGREGATES",
  GET_CURRENT_PROJECT_SHORT_ASSESSMENTS = "GET_CURRENT_PROJECT_SHORT_ASSESSMENTS",
  GET_CURRENT_PROJECT_SHORT_REPORTS = "GET_CURRENT_PROJECT_SHORT_REPORTS",
  GET_USER = "GET_USER",
  CURRENT_PROJECT_DIVERSITY_ENABLED = "CURRENT_PROJECT_DIVERSITY_ENABLED",
}

export const getters: GetterTree<StoreState, RootState> = {
  [Getters.GET_CURRENT_PROJECT_THEME](state) {
    if (state.currentProject) {
      return state.currentProject.theme || ProjectTheme.DEFAULT_TA;
    }
    return ProjectTheme.DEFAULT_TA;
  },
  [Getters.GET_CURRENT_PROJECT](state) {
    return state.currentProject;
  },
  [Getters.GET_PROJECTS](state): V2Project[] {
    return state.organizationProjects;
  },
  [Getters.GET_CURRENT_PROJECT_BENCHMARKS](state) {
    if (!state.currentProject) {
      return [];
    }

    return state.projectBenchmarks[state.currentProject.identifier];
  },
  [Getters.GET_LITERALS](state) {
    return (module: string, version: string) => {
      if (!state.literals[module] || !state.literals[module][version]) {
        return emptyResource();
      }

      return state.literals[module][version];
    };
  },
  [Getters.CURRENT_PROJECT_DIVERSITY_ENABLED](state) {
    return state.currentProject?.features?.includes("diversity") || false;
  },
  [Getters.GET_CURRENT_PROJECT_AGGREGATES](state) {
    return state.projectAggregates[state.currentProject?.identifier || ""] || {};
  },
  [Getters.GET_CURRENT_PROJECT_SHORT_ASSESSMENTS](state) {
    const currentProject = state.currentProject;
    if (!currentProject || currentProject.identifier === "") {
      const assessments = state.organizationProjects.flatMap((p) => p.assessments || []);
      return assessments.filter((obj, index) => assessments.findIndex((item) => item.identifier === obj.identifier) === index);
    }
    return state.organizationProjects.find((p) => p.identifier === currentProject.identifier)?.assessments || [];
  },
  [Getters.GET_CURRENT_PROJECT_SHORT_REPORTS](state) {
    const currentProject = state.currentProject;
    if (!currentProject || currentProject.identifier === "") {
      const reports = state.organizationProjects.flatMap((p) => p.reports || []);
      return reports.filter((obj, index) => reports.findIndex((item) => item.identifier === obj.identifier) === index).sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
    }
    return (state.organizationProjects.find((p) => p.identifier === currentProject.identifier)?.reports || []).sort((a, b) => b.dateCreated.localeCompare(a.dateCreated));
  },
};
