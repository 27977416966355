import { User } from "@/lib/auth/User";
import { CanadidateReportInfo } from "../types";
import {
  SchemasBenchmarkBenchmark,
  SchemasV2SystemReportsProjectReport,
  SchemasV2SystemAssessmentsAssessment,
  SchemasV2SystemProjectsProject,
  ProjectAggregates,
  SchemasV2SystemProjectsProjectReport,
  SchemasV2SystemBenchmarksBenchmark,
} from "@/lib/serviceTypes";
import { ComparisonChartPreset } from "@/components/admin/ComparisonComponents/ComparisonRadarChartComponent.vue";

export type Benchmark = SchemasBenchmarkBenchmark;
export type ProjectReportData = SchemasV2SystemReportsProjectReport;
export type V2Assessment = SchemasV2SystemAssessmentsAssessment; // TODO: Rename and replace Assessment from below
export type V2AssessmentWithScoring = V2Assessment & { scoring: Record<string, unknown> };
export type V2Project = SchemasV2SystemProjectsProject;
export type V2Benchmark = SchemasV2SystemBenchmarksBenchmark;
export type ProjectReportShort = SchemasV2SystemProjectsProjectReport;

export interface TeamEffectivenessReportData {
  traitPriorities: { [key: string]: 0 | 1 | 2 };
  teamAlignmentMustHaveCustomText: string;
  teamAlignmentAllCustomText: string;
  teamMatrixMustHaveCustomText: string;
  teamMatrixTopPriorityCustomText: string;
  comparisonPresets: ComparisonChartPreset[];
  assessments: V2AssessmentWithScoring[];
  demoMode?: boolean;
}

export interface SearchReportData {
  traitPriorities: { [trait: string]: 0 | 1 | 2 };
  traitWeights: { [trait: string]: number };
  comparisonPresets: ComparisonChartPreset[];
  team: string[];
  managers: string[];
  candidates: string[];
  tags: Record<string, string[]>;
  customText: {
    bonusInsights: string;
    candidates: Record<string, string>;
    teamMatrixTopPriorityCustomText: string;
    teamMatrixMustHaveCustomText: string;
  };
  assessments: V2AssessmentWithScoring[];
  demoMode?: boolean;
}

export interface CompetencyScores {
  dedicated: number;
  influential: number;
  insightful: number;
  principled: number;
  adaptable: number;
  "people-oriented": number;
  "action-oriented": number;
}

export interface TraitScores {
  goal: number;
  decisive: number;
  adaptability: number;
  con: number;
  grit: number;
  tact: number;
  persuade: number;
  strategic: number;
  intellect: number;
  vision: number;
  empathy: number;
  mentor: number;
  relationship: number;
  integrity: number;
  auth: number;
}

export interface Assessment {
  assessmentId: string;
  ID?: string;
  project: string;
  projectName?: string;
  dateCreated: Date;
  dateCompleted?: Date;
  lastAttemptDate: Date;
  candidateName: string;
  candidateEmail?: string;
  isComplete: boolean;
  readinessScore?: string;
  competencies: CompetencyScores;
  competency_scores: CompetencyScores;
  diverse?: boolean;
  pinned: boolean;
  hasReport: boolean;
  hiringCompany?: string;
  projectType?: string;
  organization?: string;
  isDeleted?: boolean;
  attempts: number;
  expirationDate?: string;
  internalResearchConsent?: boolean;
  aggregateResultsConsent?: boolean;
  identifiableResultsConsent?: boolean;
  traitScores: TraitScores;
}

export interface Status {
  viableProspects: number;
  diversityIndex?: number;
  AssessmentsSent: number;
  AssessmentsCompleted: number;
  marginalizedGenderCount: number;
  indigenousCount: number;
  visibleMinorityCount: number;
  disabledCount: number;
  sharedDiversity: number;
}

export interface Project {
  projectId: string;
  dateCreated: string;
  organization: string;
  orgChartId: string;
  name: string;
  projectType: string;
  organizationSchema?: string[][];
  organizationNodes: Array<Record<string, string>>;
  orgChartTitle?: string;
  theme: string;
  benchmarks?: string[];
  isDeleted: boolean;
  logo?: string;
  comment?: { title: string; body: string };
  features: string[];
}

export interface OrganizationChart {
  projectId: string;
  isHiringIncluded: boolean;
  parentOfHiringNode?: number;
  orgChart: any[];
  candidates: any[];
  orgChartTitle?: string;
}

export interface Organization {
  id: string;
  displayName: string;
}

export interface Readiness {
  classname: string;
  text: string;
  tooltip: string;
}

export interface TopCompetency {
  icon: string;
  text: string;
}

export interface Competency {
  score: number;
  category: number;
  traits: string[];
  optimalContextInsights: string[];
  fragileContextInsights: string[];
  hitTraitBehaviours: string[];
  missTraitBehaviours: string[];
}

export interface ReportScores {
  adaptabilityBellcurve: number;
  actionBellcurve: number;
  dedicatedBellcurve: number;
  influentialBellcurve: number;
  insightBellcurve: number;
  peopleBellcurve: number;
  principleBellcurve: number;
  actionStatus: number;
  adaptabilityStatus: number;
  dedicatedStatus: number;
  influentialStatus: number;
  insightStatus: number;
  peopleStatus: number;
  principleStatus: number;
}

export interface ElraReportData {
  type: string;
  shortId: string;
  readiness: string;
  answersUnchanged: boolean;
  competenciesOrder: string[];
  diversity: {
    isDiverse: boolean;
    attibutes: string[];
  };
  competencies: {
    actionOriented: Competency;
    adaptable: Competency;
    dedicated: Competency;
    influential: Competency;
    insightful: Competency;
    peopleOriented: Competency;
    principled: Competency;
  };
  scoringVersion: string;
  reportVersion: string;
  comment?: { title: string; body: string };
}

export interface LiteralCache {
  [module: string]: { [version: string]: Resource };
}

export interface StoreState {
  currentProject?: V2Project;
  organizationProjects: V2Project[];
  projectThemes: Record<string, ProjectTheme>;
  projectBenchmarks: Record<string, Benchmark[]>;
  projectLoading: boolean;
  projectAssessments: Record<string, V2Assessment[]>;
  projectAggregates: Record<string, ProjectAggregates>;
  reports: Record<string, CanadidateReportInfo>;
  literals: LiteralCache;
  user: User | undefined;
}

export enum ProjectTheme {
  DEFAULT = "default",
  DEFAULT_TA = "default-ta",
  ALTERNATIVE = "alternative",
  GRAY = "gray",
}

export const themeNames = {
  [ProjectTheme.DEFAULT]: "Default - TM",
  [ProjectTheme.DEFAULT_TA]: "Default - TA",
  [ProjectTheme.ALTERNATIVE]: "Alternative",
  [ProjectTheme.GRAY]: "Gray",
};

export interface Resource {
  dateCreated: string;
  language: string;
  module: string;
  version: string;
  dictionary: { [key: string]: string };
}

export interface Template {
  id: string;
  name: string;
  template: string;
  dateCreated: string;
  isCustom: boolean;
}
