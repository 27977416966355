
import "@/assets/scripts/reportutils";

import { CanadidateReportInfo, TypedVue } from "@/store/types";
import { Component, Prop, Ref } from "vue-property-decorator";
import { ProjectTheme, Resource } from "@/store/admin/adminTypes";
import { RenderHBTemplate } from "@/lib/templates/TemplateGenerator";

@Component({})
export default class ReportRenderer extends TypedVue {
  @Prop({ required: true }) public data!: CanadidateReportInfo;
  @Prop({ required: true }) private resource!: Resource;
  @Prop({ required: true }) private template!: string;
  @Prop({ required: true }) private showPII!: boolean;
  @Prop({ default: ProjectTheme.DEFAULT }) private theme!: string;
  @Ref("template-container") private templateContainer!: HTMLElement;

  renderTemplate(): string {
    // Triggers javascript events in rendered code
    this.$nextTick(() => {
      // After report renders if matomo is loaded send analytics data for any click of anchor tags
      window.dispatchEvent(
        new CustomEvent("report-template-updated", {
          detail: { container: this.templateContainer },
        }),
      );

      if (this.$matomo) {
        const matomo = this.$matomo;

        for (const element of [...Array.from(this.templateContainer.getElementsByTagName("a")), ...Array.from(this.templateContainer.querySelectorAll("*[d-track-click]"))]) {
          element.addEventListener("click", () => {
            const eventName = element.getAttribute("d-track-event-name") || `${element.nodeName}[${element.textContent?.trim()}]`;
            matomo.trackEvent("Report", "ElementClicked", eventName, null, { elementClass: element.className, elementTag: element.nodeName });
          });
        }
      }
    });

    const templateData = {
      ...this.data.data,
      readiness: this.data.readiness,
      showPII: this.showPII,
      rs: this.resource.dictionary,
      project_theme: this.theme,
      version: this.data.version,
      candidate_display_name: this.showPII ? this.data.data.candidate_name : this.data.data.assessmentId.substring(0, 4).toUpperCase(),
    };

    this.$matomo && this.$matomo.trackEvent("Report", "Loaded", this.data.version, null, { shortId: this.data.id, projectId: this.data.projectId, version: this.data.version });
    return RenderHBTemplate(this.template, templateData, this.resource.dictionary);
  }
}
